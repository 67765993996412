import moment from 'moment'
import { computed, makeObservable } from 'mobx'

const APPROVED_CREDIT = 'approved'
const REJECTED_CREDIT = 'rejected'

class QuoteOption {
  constructor(
    id,
    carValue,
    downPayment,
    loanAmount,
    selectedInstallments,
    installmentAmount,
    createdAt,
    status
  ) {
    this.id = id
    this.carValue = carValue
    this.downPayment = downPayment
    this.loanAmount = loanAmount
    this.selectedInstallments = selectedInstallments
    this.installmentAmount = installmentAmount
    this.createdAt = createdAt
    this.status = status

    makeObservable(this, {
      // computeds
      isApproved: computed,
      isRejected: computed,
      isPending: computed,
    })
  }

  get isApproved() {
    return this.status === APPROVED_CREDIT
  }

  get isRejected() {
    return this.status === REJECTED_CREDIT
  }

  get isPending() {
    return !this.status || (!this.isApproved && !this.isRejected)
  }

  static fromJson({
    id,
    carValue,
    downPayment,
    loanAmount,
    selectedInstallments,
    installmentAmount,
    createdAt,
    status,
  }) {
    return new QuoteOption(
      id,
      carValue,
      downPayment,
      loanAmount,
      selectedInstallments,
      installmentAmount,
      moment(createdAt).format('DD/MM/YYYY'),
      status
    )
  }
}

export default QuoteOption
