export const USER = '/usuario'

const SUBDOMAIN_ROUTE = `${USER}/`

const SIGN_IN_BASE = `${SUBDOMAIN_ROUTE}registro`
export const LOGIN = `${SUBDOMAIN_ROUTE}login`
export const SIGN_IN = `${SIGN_IN_BASE}/:personId/:agreedLoanId`
export const PRE_IDENTITY = `${SUBDOMAIN_ROUTE}pre-identidad`
export const DOCUMENTATION = `${SUBDOMAIN_ROUTE}documentacion`
export const FORGOT_PASSWORD = `${SUBDOMAIN_ROUTE}olvide-contraseña`
export const CHANGE_PASSWORD = `${SUBDOMAIN_ROUTE}cambiar-contraseña`
export const LOAD_DOCUMENTS = `${SUBDOMAIN_ROUTE}documentos/:id`
export const SELECT_QUOTE = `${SUBDOMAIN_ROUTE}seleccionar-cotizacion`
export const REFERENCES = `${SUBDOMAIN_ROUTE}referencias/:categoryId/:id`
export const DOCUMENTATION_SUCCESS = `${SUBDOMAIN_ROUTE}felicitaciones`

export const loadDocumentsRoute = (id) => {
  return `${LOAD_DOCUMENTS.replace(':id', id)}`
}

export const signInRoute = (personId, agreedLoanId) => {
  return `${SIGN_IN.replace(':personId', personId).replace(':agreedLoanId', agreedLoanId)}`
}

export const isSignInRoute = (route) => {
  return (
    route.includes(SIGN_IN_BASE) &&
    route.split('/').length === 5 &&
    route.split('/')[route.split('/').length - 1]
  )
}

export const referencesRoute = (categoryId, referenceId) => {
  return `${REFERENCES.replace(':categoryId', categoryId).replace(':id', referenceId)}`
}

export const userPath = (route) => route.replace(SUBDOMAIN_ROUTE, '')

// ROUTE ORDER BY NAME :) !!
export default {
  USER,
  CHANGE_PASSWORD,
  DOCUMENTATION_SUCCESS,
  DOCUMENTATION,
  FORGOT_PASSWORD,
  LOAD_DOCUMENTS,
  LOGIN,
  PRE_IDENTITY,
  SELECT_QUOTE,
  SIGN_IN,
}
