/* eslint-disable no-param-reassign */
import axios from 'axios'
import JWT from 'core/models/JWT'

const axiosInterceptors = (authStore) => {
  // Handles all 403 requests
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response !== undefined && error.response.status > 403) {
        error.response.unknown = true
      }

      return Promise.reject(error)
    }
  )

  // Adds bearer to all API requests
  axios.interceptors.request.use(
    (config) => {
      config.headers = {
        'X-API-KEY': process.env.REACT_APP_AUTHENTICATION,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }

      if (authStore.isAuthenticated) {
        config.headers.Authorization = `Bearer ${authStore.authUser.token}`
      }

      // Do something before request is sent
      return config
    },
    (error) => Promise.reject(error)
  )

  // Token update
  axios.interceptors.response.use(
    (response) => {
      if (response?.data?.meta?.token?.accessToken && response?.data?.meta?.token?.expiresAt) {
        // updates jwtoken if needed
        const token = JWT.fromJson(response.data.meta.token)
        authStore.updateToken(token.accessToken, token.expiration)
      }
      return response
    },
    (error) => {
      if (error?.response?.status === 401) {
        authStore.logout()
      }

      return Promise.reject(error)
    }
  )
}

export default axiosInterceptors
