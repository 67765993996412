import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react'
import { ClientModule, DealershipModule, UserModule, PrivacyAuthComponent } from './routes'
import { CLIENT, DEALERSHIP, USER, PRIVACY_AUTH } from './paths'

const Router = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={null}>
        <Routes>
          <Route path={PRIVACY_AUTH} element={<PrivacyAuthComponent />} />
          <Route path={DEALERSHIP} element={<DealershipModule />} />
          <Route path={USER} element={<UserModule />} />
          <Route path={CLIENT} element={<ClientModule />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default observer(Router)
