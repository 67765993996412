import { API_URL } from 'core/services/config'
import axios from 'axios'
import QuoteOption from 'core/models/QuoteOption'

class SelectQuoteService {
  getQuotes = () => {
    return axios
      .get(`${API_URL}/agreedLoan/get`)
      .then((response) => response.data.data.map((quote) => QuoteOption.fromJson(quote)))
  }

  saveSelectedQuote = (id) => {
    return axios.post(`${API_URL}/agreedLoan/select`, { id }).then((res) => res.data.data)
  }
}

export default SelectQuoteService
