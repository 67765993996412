import React from 'react'
import PropTypes from 'prop-types'
import { RootStoreProvider } from 'core/providers/RootStoreProvider'

const AppProviders = ({ children }) => {
  return <RootStoreProvider>{children}</RootStoreProvider>
}

AppProviders.propTypes = {
  children: PropTypes.element.isRequired,
}

export default AppProviders
