import axios from 'axios'
import i18next from 'i18next'
import { API_URL } from 'core/services/config'
import {
  DEPENDENT,
  INDEPENDENT,
  LEAD_SOURCE_PARAM,
  LESS_THAN_SIX_MONTHS,
  LESS_THAN_TWELVE_MONTHS,
  MORE_THAN_SIX_MONTHS,
  MORE_THAN_TWELVE_MONTHS,
} from 'core/constants'
import DealershipAuthService from 'modules/Dealership/services/DealershipAuthService'
import DealershipOption from 'modules/Dealership/models/DealershipOption'
import PrivacyAcceptance from 'core/models/PrivacyAcceptance'
import Settings from 'core/models/Settings'
import SellerProduct from 'core/models/SellerProduct'

const DEALERSHIP_CREDIT_UUID = 'dealership-credit-uuid'

class DealershipDataService {
  static http() {
    const http = axios.create()
    http.interceptors.request.use(
      (config) => {
        const sessionToken = DealershipAuthService.getSessionId()
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }

        if (sessionToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${sessionToken}`
        }

        return config
      },
      (error) => Promise.reject(error)
    )
    // Limpiamos data de session y recargamos para que se vuelva a loguear. La sesion expiro.
    http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.data?.error?.code === 'invalid_token'
        ) {
          DealershipAuthService.deauthenticate()
          window.location.reload()
        }
        return Promise.reject(error)
      }
    )

    return http
  }

  static creditUUID = () =>
    DealershipDataService.getCreditUUID() ? `/${DealershipDataService.getCreditUUID()}` : ''

  static getCreditUUID() {
    return JSON.parse(sessionStorage.getItem(DEALERSHIP_CREDIT_UUID))
  }

  static setCreditUUID(value) {
    sessionStorage.setItem(DEALERSHIP_CREDIT_UUID, JSON.stringify(value))
  }

  static clearCreditUUID() {
    sessionStorage.removeItem(DEALERSHIP_CREDIT_UUID)
  }

  static getPrivacyAcceptanceStatus(json = null) {
    if (json) {
      return PrivacyAcceptance.fromJson(json)
    }

    return DealershipDataService.http()
      .get(`${API_URL}/sessions${DealershipDataService.creditUUID()}/privacy-acceptances/view`)
      .then((res) => PrivacyAcceptance.fromJson(res.data.data))
  }

  static sendPrivacyAcceptance(email, phoneNumber) {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions${DealershipDataService.creditUUID()}/privacy-acceptances`, {
        email,
        phoneNumber,
      })
      .then((res) => PrivacyAcceptance.fromJson(res.data.data))
  }

  static resendPrivacyAcceptance(email, phoneNumber) {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions${DealershipDataService.creditUUID()}/privacy-acceptances/resend`, {
        email,
        phoneNumber,
      })
      .then((res) => res.data.data)
  }

  static confirmBureauCode(confirmationCode) {
    return DealershipDataService.http()
      .put(`${API_URL}/sessions${DealershipDataService.creditUUID()}/privacy-acceptances`, {
        confirmationCode,
      })
      .then((res) => res.data.data)
  }

  static parseSessionData(session) {
    return {
      ...session,
      personalData: session.personSnapshot,
      additionalPersonalData: session.financialSnapshot,
      simulation: !Array.isArray(session.simulation) ? session.simulation : null,
      familyMember: session.cosigner,
      selectedInstallment: true,
      dealership: session.dealership,
      branch: session.branch,
    }
  }

  static getDealershipOptions() {
    return DealershipDataService.http()
      .get(`${API_URL}/sellers/dealerships`)
      .then((res) => {
        return res.data.data.map((option) => DealershipOption.fromJson(option))
      })
  }

  static getDataSession() {
    return DealershipDataService.http()
      .get(`${API_URL}/sessions${DealershipDataService.creditUUID()}`)
      .then((r) => {
        const response = r.data.data
        return DealershipDataService.parseSessionData(response)
      })
  }

  static sendData({ ...data }) {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions${DealershipDataService.creditUUID()}/data`, { ...data })
      .then((res) => res.data.data)
  }

  static getWorkTypeOptions() {
    return DealershipDataService.workTypeOptions
  }

  static getSellerProductOptions() {
    return DealershipDataService.http()
      .get(`${API_URL}/sellers/products`)
      .then((response) => response.data.data.map((option) => SellerProduct.fromJson(option)))
  }

  static getWorkTimeOptions(option) {
    if (!option) {
      return []
    }

    if (option.id === DEPENDENT) {
      return DealershipDataService.workDependentTimeOptions
    }

    return DealershipDataService.workIndependentTimeOptions
  }

  static workTypeOptions = [
    { id: DEPENDENT, name: i18next.t('additionalPersonalData:dependent') },
    { id: INDEPENDENT, name: i18next.t('additionalPersonalData:independent') },
  ]

  static workDependentTimeOptions = [
    {
      id: LESS_THAN_SIX_MONTHS,
      name: i18next.t('additionalPersonalData:lessThanSixMonths'),
    },
    { id: MORE_THAN_SIX_MONTHS, name: i18next.t('additionalPersonalData:moreThanSixMonths') },
  ]

  static workIndependentTimeOptions = [
    {
      id: LESS_THAN_TWELVE_MONTHS,
      name: i18next.t('additionalPersonalData:lessThanTwelveMonths'),
    },
    {
      id: MORE_THAN_TWELVE_MONTHS,
      name: i18next.t('additionalPersonalData:moreThanTwelveMonths'),
    },
  ]

  static confirm({ ...data } = {}) {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions${DealershipDataService.creditUUID()}/confirmation`, {
        ...data,
      })
      .then((res) => res.data.data)
  }

  static fetchSettings() {
    return DealershipDataService.http()
      .get(
        `${API_URL}${
          DealershipDataService.creditUUID() ? `/sessions${DealershipDataService.creditUUID()}` : ''
        }/settings`
      )
      .then((res) => Settings.fromJson(res.data.data))
  }

  static sendEmail() {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions${DealershipDataService.creditUUID()}/draft`)
      .then((res) => res)
  }

  static sessionRequest() {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions`, { leadSource: LEAD_SOURCE_PARAM })
      .then((res) => res.data.data)
  }
}

export default DealershipDataService
