class DealershipOption {
  constructor(id, name, branches) {
    this.id = id
    this.name = name
    this.branches = branches
  }

  static fromJson({ id, name, branches }) {
    return new DealershipOption(id, name, branches)
  }
}

export default DealershipOption
