import { makeObservable, observable } from 'mobx'

const DEFAULT_CAR_AGE = 10

export default class Settings {
  maintenanceMode = false

  constructor(
    installments = [],
    maxVehicleValue = null,
    minVehicleValue = null,
    maxDownPaymentPercentage = null,
    minDownPaymentPercentage = null,
    minCreditAmount = null,
    maxCreditAmount = null,
    maxCarAge = null,
    rci = null,
    minAge = null,
    maxAge = null,
    minSalary = null,
    interestRate = null,
    lifeInsurance = null,
    outstandingBalanceInsurance = null,
    unemploymentInsurance = null,
    administration = null,
    gps = null,
    inscription = null,
    pledge = null,
    lowerRate = null,
    higherRate = null,
    comparatorRate = null,
    uf = null,
    resendTime = null,
    maintenanceMode = false,
    dependentMinAgeAtJob = null,
    independentMinAgeAtJob = null
  ) {
    this.installments = installments
    this.maxVehicleValue = maxVehicleValue
    this.minVehicleValue = minVehicleValue
    this.maxDownPaymentPercentage = maxDownPaymentPercentage
    this.minDownPaymentPercentage = minDownPaymentPercentage
    this.minCreditAmount = minCreditAmount
    this.maxCreditAmount = maxCreditAmount
    this.maxCarAge = maxCarAge || DEFAULT_CAR_AGE
    this.rci = rci
    this.minAge = minAge
    this.maxAge = maxAge
    this.minSalary = minSalary
    this.interestRate = interestRate
    this.lifeInsurance = lifeInsurance
    this.outstandingBalanceInsurance = outstandingBalanceInsurance
    this.unemploymentInsurance = unemploymentInsurance
    this.administration = administration
    this.gps = gps
    this.inscription = inscription
    this.pledge = pledge
    this.lowerRate = lowerRate
    this.higherRate = higherRate
    this.comparatorRate = comparatorRate
    this.resendTime = resendTime
    this.uf = uf
    this.maintenanceMode = maintenanceMode
    this.dependentMinAgeAtJob = dependentMinAgeAtJob
    this.independentMinAgeAtJob = independentMinAgeAtJob

    makeObservable(this, {
      // observables
      maintenanceMode: observable,
    })
  }

  get installmentConfig() {
    return {
      installments: this.installments,
      rate: {
        lower: this.lowerRate,
        higher: this.higherRate,
        comparator: this.comparatorRate,
      },
      uf: this.uf,
      insurances: {
        life: this.lifeInsurance,
        unemployment: this.unemploymentInsurance,
        outstandingBalance: this.outstandingBalanceInsurance,
      },
      charges: {
        inscription: this.inscription,
        pledge: this.pledge,
        administration: this.administration,
        gps: this.gps,
      },
      tax: { rate: this.interestRate },
    }
  }

  static fromJson({
    simulation,
    tax,
    installments,
    maintenanceMode,
    insurances,
    uf,
    charges,
    rate,
    privacyAcceptance,
  }) {
    const {
      rci,
      minAge,
      maxAge,
      minSalary,
      minVehicleValue,
      minDownPaymentPercentage,
      minCreditAmount,
      dependent,
      independent,
      maxVehicleValue,
      maxDownPaymentPercentage,
      maxCreditAmount,
      maxCarAge,
    } = simulation

    // INSURANCES
    const { life, outstandingBalance, unemployment } = insurances

    // CHARGES
    const { administration, gps, inscription, pledge } = charges

    // RATES
    const { lower, higher, comparator } = rate

    return new Settings(
      installments,
      maxVehicleValue,
      minVehicleValue,
      maxDownPaymentPercentage,
      minDownPaymentPercentage,
      minCreditAmount,
      maxCreditAmount,
      maxCarAge,
      rci,
      minAge,
      maxAge,
      minSalary,
      tax.rate,
      life,
      outstandingBalance,
      unemployment,
      administration,
      gps,
      inscription,
      pledge,
      lower,
      higher,
      comparator,
      uf,
      privacyAcceptance?.resendTime,
      maintenanceMode,
      dependent.minAgeAtJob,
      independent.minAgeAtJob
    )
  }
}
