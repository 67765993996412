try {
  import(
    /* webpackChunkName: "Sentry" */
    /* webpackPrefetch: true */
    '@sentry/browser'
  ).then((Sentry) => {
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.REACT_APP_SENTRY_RELEASE &&
      process.env.REACT_APP_SENTRY_DSN
    ) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.NODE_ENV,
        release: process.env.REACT_APP_SENTRY_RELEASE,
      })
    }
  })
} catch (e) {
  // eslint-disable-next-line no-console
  console.error('Error initializing sentry service')
}
