import { observable, computed, action, makeObservable } from 'mobx'
import {
  VALIDATION_ERROR_REQUEST,
  SERVER_ERROR_REQUEST,
  NETWORK_ERROR_REQUEST,
  FIELD_ERROR_REQUEST,
  GENERIC_REQUEST_ERROR,
  ERROR_CODE_401_MESSAGE,
  ERROR_CODE_403_MESSAGE,
  ERROR_CODE_404_MESSAGE,
  ERROR_CODE_500_MESSAGE,
  ERROR_CODE_503_MESSAGE,
  ERROR_GENERIC_MESSAGE,
  ERROR_LOST_CONNECTION,
} from 'core/constants'

class AsyncStore {
  constructor() {
    this.isLoading = false
    this.serverError = false
    this.networkError = false
    this.validationError = false
    this.errorStatus = null
    this.errors = []
    this.errorMsg = null
    this.makeObservable()
  }

  requestProcess(request = null) {
    this.clearError()
    this.tryAgainRequest = request
  }

  tryAgain() {
    if (this.tryAgainRequest) {
      this.tryAgainRequest()
    }

    return null
  }

  preRequest(request) {
    this.isLoading = true
    this.errors = []
    this.requestProcess(request)
  }

  onSuccessRequest() {
    this.isLoading = false
  }

  clearError(modalClose = false) {
    this.errorStatus = null
    if (modalClose) {
      setTimeout(() => {
        this.errorMsg = null
      }, 2000)
    } else {
      this.errorMsg = null
    }
  }

  setErrorMsg(msgError) {
    this.errorMsg = msgError
  }

  setErrorStatus(errorType) {
    this.errorStatus = errorType
  }

  finishRequest() {
    this.isLoading = false
  }

  onErrorRequest(errorData, args = null, isFieldError = false, customValidation) {
    this.finishRequest()
    this.clearError()
    // Se debe enviar una función para un fieldError
    if (errorData != null && args != null && isFieldError === true) {
      errorData.apply(this, args)
      this.setErrorStatus(FIELD_ERROR_REQUEST)
    } else if (errorData != null && args != null && !isFieldError && customValidation) {
      errorData.apply(this, args)
      this.setErrorStatus(FIELD_ERROR_REQUEST)
    } else {
      if (!window.navigator.onLine) {
        this.isNetworkError()
      }

      if (this.isValidationError(errorData)) {
        this.setErrorStatus(VALIDATION_ERROR_REQUEST)
      }

      if (this.isServerError(errorData)) {
        this.setErrorStatus(SERVER_ERROR_REQUEST)
      }

      if (this.isGenericError()) {
        this.setErrorStatus(GENERIC_REQUEST_ERROR)
      }
    }
  }

  get hasServerError() {
    return this.errorStatus === SERVER_ERROR_REQUEST || this.errorStatus === GENERIC_REQUEST_ERROR
  }

  get hasNetworkError() {
    return this.errorStatus === NETWORK_ERROR_REQUEST
  }

  get hasValidationError() {
    return this.errorStatus === VALIDATION_ERROR_REQUEST
  }

  get hasErrorMessage() {
    return this.errorMsg
  }

  isServerError(error) {
    // Setear según el código de error el mensaje a mostrar
    if (error?.data?.error?.code === 500) {
      this.setErrorMsg(ERROR_CODE_500_MESSAGE)
      return true
    }

    if (error?.data?.error?.code === 503) {
      this.setErrorMsg(ERROR_CODE_503_MESSAGE)
      return true
    }

    return false
  }

  isValidationError(error) {
    if (error?.data?.error?.code === 403) {
      this.setErrorMsg(ERROR_CODE_403_MESSAGE)
      return true
    }

    if (error?.data?.error?.code === 401) {
      this.setErrorMsg(ERROR_CODE_401_MESSAGE)
      return true
    }

    if (error?.data?.error?.code === 404) {
      this.setErrorMsg(ERROR_CODE_404_MESSAGE)
      return true
    }

    return false
  }

  isNetworkError() {
    this.setErrorStatus(NETWORK_ERROR_REQUEST)
    this.setErrorMsg(ERROR_LOST_CONNECTION)
  }

  isGenericError() {
    if (!this.hasServerError && !this.hasNetworkError && !this.hasValidationError) {
      this.setErrorMsg(ERROR_GENERIC_MESSAGE)
      return true
    }

    return false
  }

  makeObservable() {
    makeObservable(this, {
      // observables
      isLoading: observable,
      errors: observable,
      serverError: observable,
      networkError: observable,
      validationError: observable,
      errorStatus: observable,
      errorMsg: observable,
      // actions
      preRequest: action,
      finishRequest: action,
      setErrorStatus: action,
      setErrorMsg: action,
      clearError: action,
      // computeds
      hasServerError: computed,
      hasNetworkError: computed,
      hasValidationError: computed,
      hasErrorMessage: computed,
    })
  }
}

export default AsyncStore
