import axios from 'axios'
import { API_URL } from 'core/services/config'
import JWT from 'core/models/JWT'
import AuthService from 'modules/User/services/AuthService'
import { LEAD_SOURCE_PARAM } from 'core/constants'

export const UUID = 'Session-Uuid'
const USER_ID = 'user-id'
const SELECTED_INSTALLMENT = 'selected_installment'
const DEFAULT_EXPIRY_TIME = Math.round(+new Date() / 1000) * 7200 // two hours expiration
const CREDIT_UUID = 'credit-uuid'

class ClientAuthService {
  static http() {
    const http = axios.create()
    http.interceptors.request.use(
      (config) => {
        const sessionUuid = ClientAuthService.getSessionId()
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }

        if (sessionUuid) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${sessionUuid}`
        }

        return config
      },
      (error) => Promise.reject(error)
    )

    // Limpiamos data de session y recargamos para que se vuelva a loguear. La sesion expiro.
    http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.data?.error?.code === 'invalid_token'
        ) {
          ClientAuthService.deauthenticate()
          window.location.reload()
        }

        return Promise.reject(error)
      }
    )

    return http
  }

  static creditUUID = () =>
    ClientAuthService.getCreditUUID() ? `/${ClientAuthService.getCreditUUID()}` : ''

  static getCreditUUID() {
    return JSON.parse(sessionStorage.getItem(CREDIT_UUID))
  }

  static setCreditUUID(value) {
    sessionStorage.setItem(CREDIT_UUID, JSON.stringify(value))
  }

  static clearCreditUUID() {
    sessionStorage.removeItem(CREDIT_UUID)
  }

  static getSessionId() {
    const item = sessionStorage.getItem(UUID)
    if (!item) {
      return null
    }
    return JSON.parse(item).value
  }

  static setSessionId(value, expiry = DEFAULT_EXPIRY_TIME) {
    const item = { value, expiry }
    sessionStorage.setItem(UUID, JSON.stringify(item))
  }

  static getExpirationTime() {
    const item = sessionStorage.getItem(UUID)
    if (!item) {
      return null
    }
    return JSON.parse(item).expiry
  }

  static clearSessionId() {
    return sessionStorage.removeItem(UUID)
  }

  static clearUserId() {
    sessionStorage.removeItem(USER_ID)
  }

  static clearSelectedInstallment() {
    sessionStorage.removeItem(SELECTED_INSTALLMENT)
  }

  static setSelectedInstallment(value) {
    sessionStorage.setItem(SELECTED_INSTALLMENT, value)
  }

  static getSelectedInstallment() {
    return sessionStorage.getItem(SELECTED_INSTALLMENT)
  }

  static deauthenticate() {
    ClientAuthService.clearUserId()
    ClientAuthService.clearSessionId()
    ClientAuthService.clearCreditUUID()
  }

  static getUserId() {
    return sessionStorage.getItem(USER_ID)
  }

  static setUserId(value) {
    sessionStorage.setItem(USER_ID, value)
  }

  static sessionRequest() {
    return ClientAuthService.http()
      .post(`${API_URL}/sessions`, { leadSource: LEAD_SOURCE_PARAM })
      .then((res) => {
        const response = res.data.data
        let token = null

        if (
          !(
            ClientAuthService.getSessionId() &&
            ClientAuthService.getSessionId() === AuthService.getSessionId()
          )
        ) {
          token = JWT.fromJson(response)
        }

        return { token, sessionId: response.sessionId }
      })
  }
}

export default ClientAuthService
