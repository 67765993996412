import AuthStore from 'modules/User/stores/AuthStore'
import axiosInterceptors from 'core/util/axiosInterceptors'
import DealershipAuthStore from 'modules/Dealership/stores/DealershipAuthStore'

class RootStore {
  constructor() {
    this.authStore = new AuthStore(this)
    this.dealershipAuthStore = new DealershipAuthStore(this)
  }

  static async build() {
    const NewRootStore = new RootStore()
    axiosInterceptors(NewRootStore.authStore)
    return NewRootStore
  }
}

export default RootStore
